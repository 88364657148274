import { collection, doc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore'

const db = getFirestore()
const collectionName = 'config/'

export default class Config {
  static listenAll(callback) {
    onSnapshot(collection(db, collectionName), snapshot => {
      const config = {}
      snapshot.forEach(doc => {
        config[doc.id] = doc.data()
      })
      callback(config)
    })
  }

  static async saveShutdown(shutdownData) {
    await updateDoc(doc(db, collectionName, 'shutdown'), shutdownData)
  }
}
