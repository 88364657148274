<template>
  <template v-if="organisations.length > 1">
    <v-btn class="text-truncate">
      <span class="text-truncate">{{ current.name }}</span>
      <v-menu activator="parent">
        <v-list density="compact">
          <v-list-item v-for="orga of organisations" :key="orga.id" :value="orga.id" @click="changeCurrentOrga(orga.id)">
            {{ orga.name }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
  </template>
</template>

<script>
import { useProfileStore } from '@/stores/profile'

export default {
  props: ['current', 'organisations'],
  data() {
    return {
      store: useProfileStore()
    }
  },
  methods: {
    changeCurrentOrga(orgaId) {
      this.$router.push('/')
      this.store.profile.currentOrganisation = orgaId
      this.store.profile.save().then(() => {
        this.$forceUpdate()
      })
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.text-truncate {
  width: 200px;
}
</style>
