import { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from 'vue'
import { getAuth } from 'firebase/auth'

const needAccount = true
const needOrga = true
const needAdmin = true
const hideNav = true

const SuperAdmin = true
const Dev = true

const routes = [
  { name: 'home',
    path: '/',
    alias: ['/home', '/accueil', '/app', '/applications'],
    component: () => import('@/views/home/Home.vue'),
    meta: {
      title: 'Applications',
      needAccount,
      needOrga
    }
  },
  { name: 'store',
    path: '/store',
    component: () => import('@/views/Store.vue'),
    meta: {
      title: 'Boutique',
      needAdmin,
      needOrga
    }
  },
  // Utilisateurs
  { name: 'users',
    path: '/users',
    component: () => import('@/views/Users.vue'),
    meta: {
      title: 'Utilisateurs',
      needAdmin,
      needOrga
    }
  },
  // auth
  { name: 'profile',
    path: '/profile',
    component: () => import('@/views/auth/Profile.vue'),
    meta: {
      title: 'Profil',
      needAccount
    }
  },
  { name: 'login',
    path: '/login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      title: 'Connexion',
      hideNav
    }
  },
  { name: 'register',
    path: '/register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      title: 'Enregistrement',
      hideNav
    }
  },
  { name: 'token-register',
    path: '/register/:token',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      title: 'Enregistrement',
      hideNav
    }
  },
  { name: 'lostPassword',
    path: '/lostPassword',
    component: () => import('@/views/auth/LostPassword.vue'),
    meta: {
      title: 'Mot de passe oublié',
      hideNav
    }
  },
  { name: 'resetMyPassword',
    path: '/resetMyPassword/:key',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      title: 'Réinitialiser mon mot de passe',
      hideNav
    }
  },
  // SuperAdmin
  { name: 'orga',
    path: '/orga',
    component: () => import('@/views/SuperAdmin/Organisations.vue'),
    meta: {
      title: 'Organisations',
      authLevel: {
        SuperAdmin
      }
    }
  },
  { name: 'usersSuperAdmin',
    path: '/usersSuperAdmin',
    component: () => import('@/views/SuperAdmin/Users.vue'),
    meta: {
      title: 'Utilisateurs',
      authLevel: {
        SuperAdmin
      }
    }
  },
  { name: 'config',
    path: '/config',
    component: () => import('@/views/SuperAdmin/Config.vue'),
    meta: {
      title: 'Configuration',
      authLevel: {
        SuperAdmin
      }
    }
  },
  { name: 'dev',
    path: '/dev',
    component: () => import('@/views/SuperAdmin/Dev.vue'),
    meta: {
      title: 'Dev',
      authLevel: {
        Dev
      }
    }
  },
  { name: 'tokenTester',
    path: '/tokenTester',
    component: () => import('@/views/SuperAdmin/TokenTester.vue'),
    meta: {
      title: 'TokenTester',
      authLevel: {
        Dev
      }
    }
  },
  // error
  { name: 'error404',
  path: '/:pathMatch(.*)*',
    component: () => import('@/views/errors/Error404.vue'),
    meta: {
      title: '404'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(to => {
  if (['login', 'register', 'resetMyPassword'].includes(to.name)) {
    getAuth().signOut()
  }
})

router.afterEach((to, from) => {
  nextTick(() => {
    document.title = `DigiDeck - ${to.meta.title}`
  })
})

export default router