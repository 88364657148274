import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import { createVuetify } from 'vuetify'
import { light } from '@/../themeConfig'

export default createVuetify({
  theme: {
    defaultTheme: 'light',
    themes: {
      light
    },
  }
})
