<template>
  <div>
    <v-menu offset="8">
      <template v-slot:activator="{ props }">
        <UserAvatar :profile="store.profile" v-bind="props" class="pointer" />
      </template>

      <v-list min-width="250px">
        <div class="center px-3">
          <UserAvatar :profile="store.profile" />
          <v-col class="text-capitalize">
            <p>{{ store.profile.lastname }}</p>
            <small>{{ store.profile.firstname }}</small>
          </v-col>
        </div>
        <div v-if="['Admin', 'SuperAdmin'].includes(store.profile.role)">
          <v-divider />
          <v-list-item>
            <p>Role : <b>{{ store.profile.role }}</b></p>
          </v-list-item>
        </div>
        <v-divider class="mb-1" />
        <v-list-item link @click="$router.push('/profile')">
          <template v-slot:prepend>
            <v-icon>mdi-account-outline</v-icon>
          </template>
          <v-list-item-title>Profil</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="logout">
          <template v-slot:prepend>
            <v-icon>mdi-logout-variant</v-icon>
          </template>
          <v-list-item-title>Déconnexion</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { getAuth } from 'firebase/auth'
import { useProfileStore } from '@/stores/profile'
import UserAvatar from '@/components/user/UserAvatar.vue'

export default {
  components: { UserAvatar },
  data() {
    return {
      store: useProfileStore()
    }
  },
  methods: {
    logout() {
      this.store.profile = null
      getAuth().signOut()
      this.$router.push('/login')
    }
  }
}
</script>
