<template>
  <div class="wrapper" :class="getClass()">
    <v-btn large icon v-if="breakpoint.smAndDown.value" @click="isClose = false" class="hamb-btn" elevation="0" color="background">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <nav :class="getClass()" @mouseenter="isHover = true" @mouseleave="isHover = false">
      <!-- top -->
      <div class="top-section px-1">
        <v-btn large icon v-if="breakpoint.smAndDown.value" @click="isClose = true" elevation="0" color="background">
          <v-icon large>mdi-close</v-icon>
        </v-btn>
        <img :src="appLogo" class="pointer" @click="$router.push('/')">
        <div class="checkbox" v-if="!breakpoint.smAndDown.value">
          <v-btn @click="isLarge = !isLarge" icon elevation="0" color="transparent" size="small">
            <v-icon size="x-large" color="text">{{ isLarge ? 'mdi-circle-slice-8' : 'mdi-circle-outline' }}</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- menu -->
      <v-list class="ma-0 pa-0 bg-transparent overflow-hidden">
        <template v-for="(item, index) of items">
          <template v-if="checkIfItemIsDisplay(item)">
            <!-- classic -->
            <router-link :to="{ name: item.routeName }" v-if="!item.children || item.children.length <= 0" class="text-decoration-none" @click="isClose = true">
              <v-list-item class="list-item" :class="checkIfActive(item.routeName)">
                <template v-slot:prepend>
                  <v-icon size="25">{{ item.icon }}</v-icon>
                </template>
                <p>{{ item.title }}</p>
              </v-list-item>
            </router-link>

            <!-- group -->
            <v-list-group v-else>
              <template v-slot:activator="{ props }">
                <v-list-item v-bind="props" class="list-item">
                  <template v-slot:prepend>
                    <v-icon size="25">{{ item.icon }}</v-icon>
                  </template>
                  <p>{{ item.title }}</p>
                </v-list-item>
              </template>

              <template v-for="child of item.children" :key="child.title">
                <router-link :to="{ name: child.routeName }" v-if="checkIfItemIsDisplay(child)" class="text-decoration-none" @click="isClose = true">
                  <v-list-item
                    class="list-item" :class="checkIfActive(child.routeName)" density="compact"
                    :style="!isLarge && !isHover ? 'padding-left: 20px!important' : 'padding-left: 32px!important'">
                    <template v-slot:prepend>
                      <v-icon size="20">{{ child.icon }}</v-icon>
                    </template>
                    <p class="text-subtitle-2 font-weight-regular">{{ child.title }}</p>
                  </v-list-item>
                </router-link>
              </template>
            </v-list-group>
          </template>
        </template>
      </v-list>
    </nav>
  </div>
</template>

<script>
import { useProfileStore } from '@/stores/profile'
import items from '@/components/layout/navigation/item'
import { useDisplay } from 'vuetify'

export default {
  props: ['currentOrga'],
  setup() {
    const { name, xs, sm, smAndDown } = useDisplay()
    const breakpoint = { name, xs, sm, smAndDown }
    return { breakpoint }
  },
  data() {
    return {
      routes: this.$router.getRoutes(),
      store: useProfileStore(),
      groupIsOpen: {},
      lastOpen: null,
      isClose: true,
      isLarge: true,
      isHover: false,
      pigMode: localStorage.pigMode == "true",
      items
    }
  },
  computed: {
    appLogo() {
      return this.pigMode ? require('@/assets/images/logoPig.svg') : require('@/assets/images/logo.svg')
    }
  },
  watch: {
    isHover(newVal) {
      if (this.isLarge === false) {
        if (newVal) {
          if (this.lastOpen) {
            this.groupIsOpen[this.lastOpen] = true
          }
        } else {
          for (const key in this.groupIsOpen) {
            if (this.groupIsOpen[key] === true) {
              this.lastOpen = key
            }
            this.groupIsOpen[key] = false
          }
        }
      }
    },
    $route() {
      this.$forceUpdate()
    }
  },
  methods: {
    getClass() {
      let classes = 'bg-white elevation-1 '

      if (this.breakpoint.name.value === 'xs') {
        classes += 'mobile'
      } else if (this.breakpoint.name.value === 'sm') {
        classes += 'hidden'
      } else {
        if (this.isLarge) {
          classes += 'large'
        } else {
          classes += 'small'
        }
      }

      if (this.isClose) {
        classes += ' isClose'
      }

      return classes
    },
    checkIfActive(name) {
      if (name === this.$route.name) {
        return 'isActive'
      }
    },
    checkIfItemIsDisplay(item) {
      if(this.store.profile.role == 'SuperAdmin'){
        return true
      }else{
        if (item.children) {
          return item.children.some(child => this.checkIfItemIsDisplay(child))
        } else {
          const route = this.routes.find(r => r.name === item.routeName)
          if (
            (this.store.profile && this.store.profile.dev) || (
              (!route.meta.authLevel || (route.meta.authLevel[this.store.profile.role]))
              && (!route.meta.needAdmin || (this.currentOrga && this.store.profile.organisations[this.currentOrga.id].role === 'Admin'))
            )
          ) {
            return true
          }
        }
      }
      
      return false
    }
  }
}
</script>

<style scoped lang="scss">
$large-nav-size: 250px;
$small-nav-size: 75px;

$large-image-size: 200px;
$small-image-size: 65px;

$transition-speed: 200ms;
$transition-speed-mobile: 400ms;

// global
nav {
  background-color: rgb(var(--v-theme-background));
  position: fixed;
  top: 0;
  height: 100%;
  padding-right: 12px;

  .top-section {
    display: grid;
    justify-content: space-between;
    padding: 20px 20px 20px 24px;

    img {
      transition: $transition-speed;
    }

    .checkbox {
      transition: $transition-speed;
    }
  }

  .list-item {
    width: 100%;
    display: grid;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
    transition: $transition-speed;

    i, p {
      color: rgb(var(--v-theme-text));
      opacity: 1 !important;
    }
  }

  .isActive {
    background-color: rgb(var(--v-theme-primary));

    i, p {
      color: #ffffff;
    }
  }
}

// mobile <600px
nav.mobile {
  width: 100%;
  transition: $transition-speed-mobile;
  padding-right: 0;

  &.isClose {
    transform: translateY(-100%);
  }

  .top-section {
    grid-template-columns: 1fr auto;

    img {
      width: $large-image-size;
      max-width: $large-image-size;
      max-height: $large-image-size;
    }
  }

  .list-item {
    grid-template-columns: auto 1fr auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

// hidden >600px <960px
nav.hidden {
  transition: $transition-speed-mobile;

  &.isClose {
    transform: translateX(-100%);
  }

  &:not(&.isClose) {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    .top-section {
      grid-template-columns: 1fr auto;

      img {
        width: $large-image-size;
        max-width: $large-image-size;
        max-height: $large-image-size;
      }
    }
  }

  .list-item {
    grid-template-columns: auto 1fr auto;
  }
}

// large nav
nav.small:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

nav.large,
nav.small:hover {
  width: $large-nav-size;
  transition: $transition-speed;

  .top-section {
    place-items: center;
    grid-template-columns: auto auto;

    img {
      width: $large-image-size;
      max-width: $large-image-size;
      max-height: $large-image-size;
    }
  }

  .list-item {
    grid-template-columns: auto 1fr auto;
  }
}

// small nav
nav:not(:hover).small {
  width: $small-nav-size;
  transition: $transition-speed;

  .top-section {
    place-items: center;
    grid-template-columns: auto 0px;

    img {
      width: $small-image-size;
      max-width: $small-image-size;
      max-height: $small-image-size;
    }

    .checkbox {
      transform: scale(0);
    }
  }

  .list-item {
    grid-template-columns: auto 0px 0px;
  }
}



// hamburger button
.hamb-btn {
  position: absolute;
  margin: 12px;
}

.wrapper {
  transition: $transition-speed;
  z-index: 999;
}

.wrapper.mobile,
.wrapper.hiddent {
  width: 0;
}

.wrapper.large {
  width: $large-nav-size;
}

.wrapper.small {
  width: $small-nav-size;
}
</style>
