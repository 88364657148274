<template>
  <v-footer class="d-flex justify-space-around bg-transparent">
    <i>{{`LP (v. ${version}) Copyright ©${new Date().getFullYear()} LoesusPedagogia`}}</i>
  </v-footer>
</template>

<script>
import packageJSON from '@/../package.json'

export default {
  data() {
    return {
      version: packageJSON.version
    }
  }
}
</script>