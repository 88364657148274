import { getFirestore, doc, collection, query, where, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc, deleteField } from 'firebase/firestore'

const db = getFirestore()
const collectionName = 'profiles/'

function docToInstance(document) {
  const data = document.data()
  return !data ? null : new Profile(
    document.id, data.email, data.firstname, data.lastname, data.avatar, data.color,
    data.role, data.dev, data.commercial, data.banned, data.activated, data.currentOrganisation, data.currentApps, data.organisations, data.appdata,
    data.created_at, data.activated_at, data.last_login_at, data.last_pwChange_at
  )
}

export default class Profile {
  constructor(id, email, firstname, lastname, avatar, color, role, dev, commercial, banned, activated, currentOrganisation, currentApps, organisations, appdata, created_at, activated_at, last_login_at, last_pwChange_at) {
    this.id = id
    this.email = email
    this.firstname = firstname
    this.lastname = lastname
    this.avatar = avatar
    this.color = color
    this.role = role
    this.dev = dev
    this.commercial = commercial
    this.banned = banned
    this.activated = activated
    this.currentOrganisation = currentOrganisation
    this.currentApps = currentApps
    this.organisations = organisations
    this.appdata = appdata

    this.created_at = created_at
    this.activated_at = activated_at
    this.last_login_at = last_login_at
    this.last_pwChange_at = last_pwChange_at
  }

  static async add(uid, email, firstname, lastname, avatar = '0.png', color = 185, organisations) {
    const now = new Date().getTime()
    const new_profile = {
      email: email,
      firstname: firstname,
      lastname: lastname,
      avatar: avatar,
      color: color,
      role: 'User',
      created_at: now
    }
    if (organisations) {
      new_profile.organisations = organisations
    }

    await setDoc(doc(db, collectionName, uid), new_profile)
    new_profile.id = uid
    return Object.assign(new Profile(), new_profile)
  }

  async saveParams() {
    const new_user = {}
    if (this.dev !== undefined) {
      new_user.dev = this.dev ? true : deleteField()
    }
    if (this.commercial !== undefined) {
      new_user.commercial = this.commercial ? true : deleteField()
    }

    if (this.organisations !== undefined) {
      const organisationsKeys = Object.keys(this.organisations)
      if (organisationsKeys.length === 0) {
        new_user.organisations = deleteField()
        this.currentOrganisation = deleteField()
      } else {
        new_user.organisations = this.organisations
        if (!organisationsKeys.includes(this.currentOrganisation)) {
          new_user.currentOrganisation = organisationsKeys[0]
        }
      }
    }

    await updateDoc(doc(db, collectionName, this.id), new_user)
  }
  async saveLastLogin() {
    const now = new Date().getTime()
    await updateDoc(doc(db, collectionName, this.id), {
      last_login_at: now
    })
  }
  async saveLastPwChange() {
    const now = new Date().getTime()
    await updateDoc(doc(db, collectionName, this.id), {
      last_pwChange_at: now
    })
  }
  async toggleBan() {
    if (this.banned) {
      await updateDoc(doc(db, collectionName, this.id), {
        banned: deleteField()
      })
    } else {
      await updateDoc(doc(db, collectionName, this.id), {
        banned: true
      })
    }
  }
  async toggleActivated() {
    if (this.activated) {
      await updateDoc(doc(db, collectionName, this.id), {
        activated: deleteField()
      })
    } else {
      await updateDoc(doc(db, collectionName, this.id), {
        activated: true
      })
    }
  }
  async save() {
    const new_profile = {
      firstname: this.firstname,
      lastname: this.lastname,
      avatar: this.avatar,
      color: this.color,
      role: this.role,
      dev: this.dev,
      commercial: this.commercial,
      banned: this.banned,
      activated: this.activated,
      currentOrganisation: this.currentOrganisation,
      currentApps: this.currentApps,
      organisations: this.organisations,
      appdata: this.appdata,
      activated_at: this.activated_at,
      last_login_at: this.last_login_at,
      last_pwChange_at: this.last_pwChange_at
    }

    for (const key of Object.keys(new_profile)) {
      if (new_profile[key] === undefined) {
        delete new_profile[key]
      }
    }

    await updateDoc(doc(db, collectionName, this.id), new_profile)
  }

  static async getByEmail(email) {
    const docQuery = query(collection(db, collectionName), where('email', '==', email))
    const response = await getDocs(docQuery)

    let profile = null
    response.forEach(document => {
      if (profile === null) {
        profile = docToInstance(document)
      } else {
        deleteDoc(doc(db, collectionName, document.id))
      }
    })
    return profile
  }
  static async getByArrayOfEmail(array) {
    if (array.length === 0) return []

    const docQuery = query(collection(db, collectionName), where('email', 'in', array))
    const response = await getDocs(docQuery)

    const list = []
    response.forEach(document => {
      list.push(docToInstance(document))
    })
    return list
  }
  static async getByOrga(orga) {
    const docQuery = query(collection(db, collectionName), where(`organisations.${orga}`, '!=', null))
    const response = await getDocs(docQuery)

    const list = []
    response.forEach(document => {
      list.push(docToInstance(document))
    })
    return list
  }
  static listenAll(callback) {
    return onSnapshot(collection(db, collectionName), snapshot => {
      const list = []
      snapshot.forEach(document => {
        list.push(docToInstance(document))
      })
      callback(list)
    })
  }
  static listenById(id, callback) {
    return onSnapshot(doc(db, collectionName, id), snapshot => {
      callback(docToInstance(snapshot))
    })
  }
  static listenByOrga(orga, callback) {
    const docQuery = query(collection(db, collectionName), where(`organisations.${orga}`, '!=', null))
    return onSnapshot(docQuery, snapshot => {
      const list = []
      snapshot.forEach(document => {
        list.push(docToInstance(document))
      })
      callback(list)
    })
  }

  async delete() {
    await deleteDoc(doc(db, collectionName, this.id))
  }
}
