<template>
	<div class="h-100 d-flex flex-column align-center justify-center">
		<h2>{{ config.shutdown.code }}</h2>
    <h3>{{ config.shutdown.title }}</h3>
		<pre class="text-center">{{ config.shutdown.message }}</pre>
	</div>
</template>

<script>
export default {
  props: ['config']
}
</script>
