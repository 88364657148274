import { getFirestore, doc, collection, addDoc, updateDoc, onSnapshot, query, where, documentId } from 'firebase/firestore'

const db = getFirestore()
const collectionName = 'organisations/'

function docToInstance(document) {
  const data = document.data()
  return !data ? null : new Organisation(document.id, data.name, data.coins, data.tags)
}
export default class Organisation {
  constructor(id, name, coins, tags = []) {
    this.id = id
    this.name = name
    this.coins = coins
    this.tags = tags
  }

  static async add(name) {
    const new_organisation = {
      name: name,
      coins: 0
    }
    const response = await addDoc(collection(db, collectionName), new_organisation)
    new_organisation.id = response.id
    return Object.assign(new Organisation(), new_organisation)
  }
  async save() {
    await updateDoc(doc(db, collectionName, this.id), {
      name: this.name,
      coins: this.coins,
      tags: this.tags
    })
  }
  async saveTags() {
    await updateDoc(doc(db, collectionName, this.id), {
      tags: this.tags
    })
  }

  static listenAll(callback) {
    return onSnapshot(collection(db, collectionName), snapshot => {
      const list = []
      snapshot.forEach(document => {
        list.push(docToInstance(document))
      })
      callback(list)
    })
  }
  static listenById(id, callback) {
    return onSnapshot(doc(db, collectionName, id), snapshot => {
      callback(docToInstance(snapshot))
    })
  }
  static listenByArrayOfIds(ids, callback) {
    if (ids.length === 0) {
      callback([])
      return
    }

    const docsQuery = query(collection(db, collectionName), where(documentId(), 'in', ids))
    return onSnapshot(docsQuery, snapshot => {
      const list = []
      snapshot.forEach(document => {
        list.push(docToInstance(document))
      })
      callback(list)
    })
  }
}
