const light = {
  dark: false,
  colors: {
    text: '#243232',
    background: '#f4f6fb',

    primary: '#DE8080',
    secondary: '#F7A7A1',

    success: '#257659',
    info: '#4CC3D0',
    warning: '#FFB400',
    error: '#E45D2B',

    //app colors

    valy: '#273B60',
    valy_light: '#3E578F',
    valy_dark: '#1C2535',

    sign_and_go: '#58A19A',
    sign_and_go_light: '#69C1B9',
    sign_and_go_dark: '#3A6E69',

    smile_and_survey: '#39663A',
    smile_and_survey_light: '#4D884E',
    smile_and_survey_dark: '#264225',

    my_first_business: '#DB9117',
    my_first_business_light: '#E6A657',
    my_first_business_dark: '#976513',

    //swal
    swalLight: '#FFFFFF',
    swalDark: '#6E6778'
  }
}

export { light }
