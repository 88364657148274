import { getFirestore, collection, query, where, onSnapshot, updateDoc, doc } from 'firebase/firestore'

const db = getFirestore()
const collectionName = 'prices/'

class Price {
  constructor(id, img, type) {
    this.id = id
    this.img = img
    this.type = type
  }
}



function docToCoinsPrice(document) {
  const data = document.data()
  return !data ? null : new CoinsPrice(document.id, data.img, data.type, data.price, data.quantity)
}
class CoinsPrice extends Price {
  constructor(id, img, type, price, quantity) {
    super(id, img, type)
    this.price = price
    this.quantity = quantity
  }

  static listenAll(callback) {
    const docsQuery = query(collection(db, collectionName), where('type', '==', 'coins'))

    return onSnapshot(docsQuery, snapshot => {
      const list = []
      snapshot.forEach(document => {
        list.push(docToCoinsPrice(document))
      })
      callback(list)
    })
  }

  async save() {
    const object = {
      price: this.price,
      quantity: this.quantity
    }

    await updateDoc(doc(db, collectionName, this.id), object)
  }
}



function docToAppPrice(document) {
  const data = document.data()
  return !data ? null : new AppPrice(document.id, data.img, data.type, data.priceInfo, data.title, data.initFunction, data.isVisible)
}
class AppPrice extends Price {
  constructor(id, img, type, priceInfo = {}, title, initFunction, isVisible = true) {
    super(id, img, type)
    this.title = title
    this.priceInfo = priceInfo
    this.initFunction = initFunction
    this.isVisible = isVisible
  }

  static listenAll(callback) {
    const docsQuery = query(collection(db, collectionName), where('type', '==', 'app'))

    onSnapshot(docsQuery, snapshot => {
      const list = []
      snapshot.forEach(document => {
        list.push(docToAppPrice(document))
      })
      callback(list)
    })
  }
  async save() {
    await updateDoc(doc(db, collectionName, this.id), {
      priceInfo: this.priceInfo,
      isVisible: this.isVisible
    })
  }



  static listenArchiving(callback) {
    onSnapshot(doc(db, collectionName, 'archiving'), snapshot => {
      callback(snapshot.data().autoArchivingDuration)
    })
  }
  static async saveArchiving(newVal) {
    await updateDoc(doc(db, collectionName, 'archiving'), {
      autoArchivingDuration: newVal
    })
  }
}



export { CoinsPrice, AppPrice }
