export default [
  {	title: 'Applications',
		icon: 'mdi-home-outline',
		routeName: 'home'
	},
	{ title: 'Utilisateurs',
		icon: 'mdi-account-outline',
		routeName: 'users'
	},
	{ title: 'Boutique',
		icon: 'mdi-basket-outline',
		routeName: 'store'
	},
	{ title: 'SuperAdmin',
		icon: 'mdi-shield-account-outline',
		children: [
			{ title: 'Utilisateurs',
				icon: 'mdi-account-outline',
				routeName: 'usersSuperAdmin'
			},
			{ title: 'Organisations',
				icon: 'mdi-town-hall',
				routeName: 'orga'
			},
			{ title: 'Configuration',
				icon: 'mdi-currency-usd',
				routeName: 'config'
			},
			{ title: 'Dev',
				icon: 'mdi-xml',
				routeName: 'dev'
			},
			{ title: 'Token tester',
				icon: 'mdi-badge-account-horizontal-outline',
				routeName: 'tokenTester'
			}
		]
	}
]